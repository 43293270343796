<template>
  <div class="mb-20 bgf">
    <div class="card-title">
      <p>扫码统计</p>
    </div>
    <div class="searchTab">
      <el-radio-group
        v-model="countChannel"
        size="medium"
        @change="setLineChart"
      >
        <el-radio-button label="MIN"> 小程序 </el-radio-button>
        <el-radio-button label="APP"> XXAPP </el-radio-button>
      </el-radio-group>
      <el-button @click="$router.back()"> 关闭 </el-button>
    </div>
    <div class="mt-20 mr-20 search">
      <span
        :class="days == 'today' ? 'active' : ''"
        @click="clickDays('today')"
      >
        今日
      </span>
      <span
        :class="days == 'yesterday' ? 'active' : ''"
        @click="clickDays('yesterday')"
      >
        昨日
      </span>
      <span :class="days == 'week' ? 'active' : ''" @click="clickDays('week')">
        最近7天
      </span>
      <span
        :class="days == 'month' ? 'active' : ''"
        @click="clickDays('month')"
      >
        最近30天
      </span>
      <el-date-picker
        v-model="date"
        :picker-options="topPickerOptions"
        size="small"
        value-format="yyyy-MM-dd"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :clearable="false"
        @change="dateChange"
      />
      <el-button type="primary" class="search-btn" @click="queryData">
        根据日期查询
      </el-button>
    </div>
    <div class="div-echart">
      <echart id="growUp1" ref="echart1" />
      <div v-if="Data.length === 0">
        <img class="nodata" src="~@/assets/noData.png" />
      </div>
    </div>
    <!-- 物料统计表格 -->
    <div class="p20">
      <div class="table-title">日期详细列表</div>
      <el-table :data="Data" :header-cell-style="headClass" style="width: 100%">
        <el-table-column label="日期" prop="dateList" align="center" />
        <el-table-column
          label="页面访问数量"
          prop="scanCodeCount"
          align="center"
        />
        <el-table-column
          label="报到总人数"
          prop="checkInCount"
          align="center"
        />
      </el-table>
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 报到表格 -->
    <el-button class="export" type="primary" @click="exportExcel">
      导出
    </el-button>
    <div class="p20">
      <el-table :data="List" :header-cell-style="headClass" style="width: 100%">
        <el-table-column label="序号" type="index" align="center" />
        <el-table-column label="报到ID" prop="checkInOrderId" align="center" />
        <el-table-column label="患者姓名" prop="patientName" align="center" />
        <el-table-column
          label="用户手机号码"
          prop="patientMobile"
          align="center"
        />
        <el-table-column label="性别" align="center">
          <div slot-scope="scope">
            {{ sexType[scope.row.patientSex] }}
          </div>
        </el-table-column>
        <el-table-column label="年龄" prop="patientAge" align="center" />
        <el-table-column label="扫码方式" align="center">
          <div slot-scope="scope">
            {{ channelType[scope.row.countChannel] }}
          </div>
        </el-table-column>
        <el-table-column label="报到金额" prop="payFee" align="center" />
        <el-table-column label="报到时间" prop="checkInDate" align="center" />
        <el-table-column label="报到医生" prop="doctorName" align="center" />
      </el-table>
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="reportPageInfo.page"
        :page-size="reportPageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="reportPageInfo.total"
        @size-change="reportPageSizeChange"
        @current-change="reportPageCurrentChange"
      />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import echart from '@/components/echart/echart.vue';
import { downloadUrl } from '@/utils/index';

export default {
  name: 'Statistics',
  components: {
    echart,
  },
  data() {
    return {
      countChannel: 'MIN',
      channelType: {
        APP: 'APP',
        MIN: '微信',
      },
      sexType: {
        1: '男',
        2: '女',
      },
      topPickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      materialId: this.$route.query.id || '',
      days: 'today',
      Data: [], // 统计列表
      List: [], // 报到记录
      pageInfo: {
        // 物料分页查询分页参数
        page: 1,
        size: 10,
        total: 0,
      },
      reportPageInfo: {
        // 报到记录分页查询
        page: 1,
        size: 10,
        total: 0,
      },
      dateType: {
        today: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        yesterday: [
          moment().subtract('days', 1).format('YYYY-MM-DD'),
          moment().subtract('days', 1).format('YYYY-MM-DD'),
        ],
        week: [
          moment().subtract('days', 6).format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        month: [
          moment().subtract('days', 29).format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
      },
    };
  },
  computed: {},
  mounted() {
    this.queryData();
  },
  methods: {
    queryData() {
      // 初始化下页码
      this.pageInfo.page = 1;
      this.reportPageInfo.page = 1;

      this.materialCountQuery();
      this.queryMaterialCheckInLog();
    },
    clickDays(days) {
      this.days = days;
      this.date = this.dateType[days];
      this.queryData();
    },
    /**
     * @description: 日期更新后与左侧指定日期联动
     * @param {*} val 日期
     * @return {*}
     */
    dateChange(val) {
      const today = this.dateType['today'];
      const yesterday = this.dateType['yesterday'];
      const week = this.dateType['week'];
      const month = this.dateType['month'];

      this.date = val;

      switch (JSON.stringify(val)) {
        case JSON.stringify(today):
          this.days = 'today';
          break;
        case JSON.stringify(yesterday):
          this.days = 'yesterday';
          break;
        case JSON.stringify(week):
          this.days = 'week';
          break;
        case JSON.stringify(month):
          this.days = 'month';
          break;
        default:
          this.days = '';
          break;
      }
    },
    headClass() {
      return 'background:#fafafa;text-align: center;color:#333;font-weight: 600;';
    },
    // 物料统计分页查询
    materialCountQuery() {
      const param = this.getcommonParams();
      this.$api.materialCountQuery(param).then((res) => {
        console.log('查询物料统计列表：', JSON.parse(JSON.stringify(res)));
        const { data } = res;
        this.pageInfo.total = data.total;
        this.Data = data.data || [];
        if (this.Data.length !== 0 && this.$refs.echart1) {
          this.$refs.echart1.drawLine(data.data);
        }
      });
    },
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.materialCountQuery();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.materialCountQuery();
    },
    // 查询物料的报到记录
    queryMaterialCheckInLog() {
      const param = this.getcommonParams(true);
      this.$api.queryMaterialCheckInLog(param).then((res) => {
        console.log('查询物料的报到记录：', JSON.parse(JSON.stringify(res)));
        const { data } = res;
        this.reportPageInfo.total = data.total;
        this.List = data.data;
      });
    },
    reportPageSizeChange(currentPage) {
      this.reportPageInfo.size = currentPage;
      this.queryMaterialCheckInLog();
    },
    reportPageCurrentChange(currentPage) {
      this.reportPageInfo.page = currentPage;
      this.queryMaterialCheckInLog();
    },
    /**
     * @description: 获取公共参数
     * @param {*} flag true: 报到入参  false: 物料统计入参
     * @return {*}
     */
    getcommonParams(flag) {
      const { countChannel, date, materialId, pageInfo, reportPageInfo } = this;
      const tempPageInfo = flag ? reportPageInfo : pageInfo;
      const param = {
        countChannel,
        startDate: date && date.length ? date[0].substr(0, 10) : '',
        endDate: date && date.length ? date[1].substr(0, 10) : '',
        materialId,
        page: {
          done: true,
          pageIndex: tempPageInfo.page,
          pageSize: tempPageInfo.size,
        },
      };
      return param;
    },
    /**
     * @description: 导出
     * @param {*}
     * @return {*}
     */
    exportExcel() {
      const param = this.getcommonParams(true);
      this.$api.exportCheckInLog(param).then((res) => {
        console.log(
          '导出物料的报到记录：',
          res,
          res.headers['content-disposition']
        );
        const fileName =
          res.headers['content-disposition']?.split(
            'attachment;filename='
          )?.[1] || '';
        downloadUrl(res.data, decodeURIComponent(fileName));
      });
    },
    setLineChart(value) {
      this.countChannel = value;
      this.queryData();
    },
  },
};
</script>
<style lang="scss" scoped>
.p20 {
  padding: 20px;
  overflow: hidden;
}
.bgf {
  background: #fff;
}
.table-title {
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: bold;
  color: #333333;
}
.card-title p {
  height: 55px;
  line-height: 55px;
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  border-bottom: 1px solid #eeeeee;
  padding-left: 36px;
  position: relative;
}
.card-title p:before {
  content: '';
  width: 6px;
  height: 20px;
  position: absolute;
  top: 17.5px;
  left: 24px;
  background: #409eff;
  border-radius: 5px;
}
.searchTab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}
.search {
  overflow: hidden;
  padding-left: 20px;
  span {
    cursor: pointer;
    margin-right: 20px;
    &.active {
      color: #4c8deb;
    }
  }
}
.search-btn {
  margin-left: 15px;
}
.div-echart {
  margin: 20px 0;
  position: relative;
  .el-icon-warning {
    transform: rotate(180deg);
    float: right;
    position: relative;
    top: 20px;
    right: 20px;
    color: #aeaeb2;
    font-size: 12px;
    z-index: 1;
  }
}
.export {
  margin-left: 20px;
}
.nodata {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
}
</style>
