<template>
  <div>
    <div
      :id="id"
      :height="height"
      :style="{ height: height }"
      class="echarts"
    />
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  name: 'Echart',
  props: {
    id: {
      type: String,
      default: () => {
        return '';
      }
    },
    height: {
      type: String,
      default: () => {
        return '400px';
      }
    }
  },
  data() {
    return {
      growUp: null
    };
  },
  mounted() {
    if (document.getElementById(this.id) === undefined) return;
    this.growUp = echarts.init(document.getElementById(this.id));
  },
  destroyed() {
    if (this.growUp) {
      this.growUp.clear();
      this.growUp.dispose();
    }
  },
  methods: {
    growUpResize() {
      if (this.growUp) {
        this.growUp.resize();
      }
    },
    growUpClear() {
      if (this.growUp) {
        this.growUp.clear();
      }
    },
    drawLine(data) {
      const dataList = JSON.parse(JSON.stringify(data));
      dataList?.reverse(); // 趋势图按日期正序排列
      this.growUp.clear();
      this.growUp.resize();
      var option = {
        legend: {
          left: '3%',
          icon: 'circle',
          data: ['访问量'],
          selected: {
            '访问量': true,
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        tooltip: {
          appendToBody: true,
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            shadowStyle: {
              opacity: 0.2
            },
            lineStyle: {
              // x坐标轴轴线样式
              color: '#1BDBE4' // '#ccc' | 'rgb(128, 128, 128)' | 'rgba(128, 128, 128, 0.5)'，设置标签颜色
            }
          }
        },
        xAxis: {
          type: 'category',
          data: dataList.map(e => {
            return e.dateList;
          })
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
          axisLabel: {
            formatter: '{value} 次'
          },
        },
        series: [
          {
            name: '访问量',
            data: dataList.map(e => {
              return e.scanCodeCount;
            }),
            type: 'line',
            smooth: true,
            itemStyle: {
              normal: {
                color: 'rgb(24,144,255)',
                lineStyle: {
                  color: 'rgb(24,144,255)'
                }
              }
            }
          },
          // {
          //   name: '访客数',
          //   data: dataList.map(e => {
          //     return e.checkInCount;
          //   }),
          //   type: 'line',
          //   smooth: true,
          //   itemStyle: {
          //     normal: {
          //       color: 'rgb(44,207,213)',
          //       lineStyle: {
          //         color: 'rgb(44,207,213)'
          //       }
          //     }
          //   }
          // }
        ]
      };
      this.growUp.setOption(option);
    }
  }
};
</script>
<style lang="scss" scoped>
.echarts {
  width: 100%;
}
</style>
